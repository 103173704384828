<template>
  <div>
    <div class="mt-3 small">
      Source <a href="https://github.com/ckarlson14/chriskarlson.life" target="_blank"><i class="fa fa-github"></i> </a>
    </div>
    <div class="small">
      Made with<a href="https://vuejs.org/" target="_blank"><i class="devicon-vuejs-plain-wordmark colored m-2"></i></a>powered by <a href="https://netlify.com" target="_blank"> Netlify</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterComponent.vue"
}
</script>

<style scoped>
a{
  color: #d6249f;
  text-decoration: none;
}

a:hover {
  color: #55acee;
}
</style>